import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { VideoForm } from '../../forms/video-form';

import { IVideoPlaylist } from '../../../core/models/video-api-payloads/video-playlist.model';
import {
  ICandidateVideo,
  ITag,
  IVideoPayload,
} from '../../../core/models/video-api-payloads/video.model';
import { IPaginatedResponse } from 'src/app/core/models/paginated-payload.model';

import { encodeParam } from 'src/app/core/utils/paramEncoder';
import { environment } from 'src/environments/environment';

export interface IServerVideoObj {
  intro: boolean;
  public: boolean;
  description: string;
  title: string;
  tags: ITag[];
}

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private baseUrl: string = environment.baseUrl + 'candidate/api';

  constructor(private http: HttpClient) {}

  public getVideos(params?: HttpParams): Observable<IPaginatedResponse<IVideoPayload>> {
    if (params) {
      return this.http.get<IPaginatedResponse<IVideoPayload>>(`${this.baseUrl}/video`, {
        params: params,
      });
    } else {
      return this.http.get<IPaginatedResponse<IVideoPayload>>(`${this.baseUrl}/video`);
    }
  }

  public getPaginatedVideos(url: string): Observable<IPaginatedResponse<IVideoPayload>> {
    return this.http.get<IPaginatedResponse<IVideoPayload>>(url);
  }

  public getPlaylists(params?: HttpParams): Observable<IVideoPlaylist[]> {
    if (params) {
      return this.http.get<IVideoPlaylist[]>(`${this.baseUrl}/playlist`, {
        params: params,
      });
    } else {
      return this.http.get<IVideoPlaylist[]>(`${this.baseUrl}/playlist`);
    }
  }

  public sharePlaylist(payload: object): Observable<IVideoPlaylist> {
    return this.http.post<IVideoPlaylist>(`${this.baseUrl}/playlist/share/`, payload);
  }

  public addEmailToSharedPlaylist(payload: object): Observable<IVideoPlaylist> {
    return this.http.put<IVideoPlaylist>(
      `${this.baseUrl}/playlist/add_email_to_allow_list/`,
      payload,
    );
  }

  public deleteSharePlaylist(payload: object): Observable<IVideoPlaylist> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload,
    };
    return this.http.delete<IVideoPlaylist>(`${this.baseUrl}/playlist/stop_sharing/`, options);
  }

  public searchVideos(searchTerm: string): Observable<IPaginatedResponse<IVideoPayload>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('search', searchTerm);

    return this.http.get<IPaginatedResponse<IVideoPayload>>(`${this.baseUrl}/video`, {
      params: queryParams,
    });
  }

  public getVideo(pk: string): Observable<IVideoPayload> {
    return this.http.get<IVideoPayload>(`${this.baseUrl}/video/${pk}`);
  }

  public getPlaylist(pk: string): Observable<IVideoPlaylist> {
    return this.http.get<IVideoPlaylist>(`${this.baseUrl}/playlist/${pk}`);
  }

  public getPublicPlaylist(id: string, email?: string): Observable<IVideoPlaylist> {
    if (email) {
      let params = new HttpParams();
      params = params.set('email', email);
      return this.http.get<IVideoPlaylist>(`${this.baseUrl}/playlist/public/${id}`, { params });
    } else {
      return this.http.get<IVideoPlaylist>(`${this.baseUrl}/playlist/public/${id}`);
    }
  }

  public postVideo(payload: VideoForm, id: string): Observable<ICandidateVideo> {
    let formData = new FormData();
    let vidName = `${payload.getVideoTitle().value}_${payload.getVideo().value.name}`;
    let thumbnailName = `${payload.getVideoTitle().value}_${payload.getThumbnail().value.name}`;

    formData.append('video', payload.getVideo().value, vidName);
    formData.append('thumbnail', payload.getThumbnail().value, thumbnailName);
    formData.append('candidate_video', id);

    return this.http.post<ICandidateVideo>(`${this.baseUrl}/upload-video/`, formData);
  }

  public deleteVideo(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/video/${id}/`);
  }

  public postVideoMetaData(payload: Object): Observable<IVideoPayload> {
    return this.http.post<IVideoPayload>(`${this.baseUrl}/video/`, payload);
  }

  public updateVideoMetaData(payload: IServerVideoObj, pk: string): Observable<IVideoPayload> {
    return this.http.put<IVideoPayload>(`${this.baseUrl}/video/${pk}/`, payload);
  }

  public createPlaylist(payload: Object): Observable<IVideoPlaylist> {
    return this.http.post<IVideoPlaylist>(`${this.baseUrl}/playlist/`, payload);
  }

  public updatePlaylist(payload: Object, id: string): Observable<IVideoPlaylist> {
    return this.http.put<IVideoPlaylist>(`${this.baseUrl}/playlist/${id}/`, payload);
  }

  public deletePlaylist(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/playlist/${id}/`);
  }

  public addVideoToPlaylist(payload: Object): Observable<IVideoPlaylist> {
    return this.http.post<IVideoPlaylist>(`${this.baseUrl}/playlist/addVideo/`, payload);
  }

  public addVideosToPlaylist(payload: Object): Observable<IVideoPlaylist> {
    return this.http.post<IVideoPlaylist>(`${this.baseUrl}/playlist/addVideos/`, payload);
  }

  public removeVideoFromPlaylist(payload: Object): Observable<IVideoPlaylist> {
    return this.http.post<IVideoPlaylist>(`${this.baseUrl}/playlist/removeVideo/`, payload);
  }

  public makePlaylistIntro(payload: Object): Observable<IVideoPlaylist> {
    return this.http.post<IVideoPlaylist>(`${this.baseUrl}/playlist/setIntro/`, payload);
  }
}
