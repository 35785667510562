import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface IDropdownItem {
  value: string | boolean | number;
  display: string;
  onClick?: Function;
}

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent implements AfterViewInit, OnInit {
  @ViewChild('dropdownContainer') dropdownContainer?: ElementRef;

  @Output() selectEvent: EventEmitter<IDropdownItem> = new EventEmitter();

  @Input() items: IDropdownItem[] = [];
  @Input() title?: string;
  @Input() placeholder: string = 'Options';
  @Input() clearable: boolean = false;
  @Input() default?: IDropdownItem;
  @Input() isIcon?: boolean;
  @Input() icon?: string;
  @Input() curvedStyle?: boolean;
  @Input() control: UntypedFormControl = new UntypedFormControl('');
  @Input() submitted = false;
  @Input() dropUp: boolean = false;

  public selectedValue?: IDropdownItem;
  public dropdownOpen: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.control.valueChanges.subscribe(() => {
      this.updateSelectedOption();
    });
  }

  ngAfterViewInit(): void {
    this.setDefaultSelectedValue();
  }

  private updateSelectedOption(): void {
    this.selectedValue = this.items.find((opt) => opt.value == this.control.value);
  }

  public toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
    this.cdr.detectChanges();
  }

  public closeDropdown(): void {
    this.dropdownOpen = false;
    this.cdr.detectChanges();
  }

  public itemClicked(item: IDropdownItem): void {
    if (item.onClick) {
      item.onClick();
    }
    this.control.setValue(item.value);
    this.selectedValue = item;
    this.selectEvent.emit(this.selectedValue);
    this.closeDropdown();
  }

  public clearSelection(): void {
    this.control.setValue('');
    this.selectedValue = undefined;
    this.selectEvent.emit(this.selectedValue);
    this.closeDropdown();
  }

  public setDefaultSelectedValue(): void {
    if (this.default) {
      const defaultItem = this.items.find((item) => item.value === this.default?.value);
      if (defaultItem) {
        this.control.setValue(defaultItem.value);
        this.selectedValue = defaultItem;
        this.selectEvent.emit(this.selectedValue);
      }
    }
    this.cdr.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && !this.dropdownContainer?.nativeElement.contains(targetElement)) {
      this.closeDropdown();
    }
  }
}
