<div
  class="relative inline-block w-full text-left"
  (clickOutside)="closeDropdown()"
  #dropdownContainer
>
  <div>
    <span *ngIf="title" class="title">{{ title }}</span>
    <button
      type="button"
      [class.!p-1]="isIcon"
      [class.!rounded-full]="curvedStyle"
      [class.error]="!control.valid && submitted && control.hasError('required')"
      [ngClass]="{ 'text-gray-900': selectedValue, 'text-gray-500': !selectedValue }"
      class="inline-flex w-full justify-between gap-x-1.5 rounded-md border-0 bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:ring-2 focus:ring-indigo-600"
      id="menu-button"
      aria-expanded="true"
      aria-haspopup="true"
      (click)="toggleDropdown()"
    >
      <img class="h-5 w-5" *ngIf="icon" src="./icons/{{ icon }}.svg" alt="" />
      @if (!isIcon) {
        <span class="truncate">{{ selectedValue ? selectedValue.display : placeholder }}</span>
        <svg
          class="-mr-1 h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      } @else {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-5 w-5 text-gray-400"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      }
    </button>
  </div>

  <div
    *ngIf="dropdownOpen"
    [ngClass]="{
      'translate-y-[-145%]': dropUp,
    }"
    class="absolute right-0 z-10 mt-2 max-h-60 w-fit origin-top-right overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition duration-100 ease-out focus:outline-none"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div class="py-1" role="none">
      <a
        *ngFor="let item of items; let last = last"
        class="block cursor-pointer text-nowrap px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        [ngClass]="{
          'border-b-[1px] !text-blue-600': item.value === 'goToResume',
        }"
        [ngClass]="{ 'border-b': !last }"
        role="menuitem"
        tabindex="-1"
        (click)="itemClicked(item)"
      >
        {{ item.display }}
      </a>
      <a
        *ngIf="clearable && selectedValue"
        class="block cursor-pointer text-nowrap border-t-[1px] border-gray-300 px-4 py-2 text-sm text-blue-600 hover:bg-gray-100"
        role="menuitem"
        tabindex="-1"
        (click)="clearSelection()"
      >
        Clear
      </a>
    </div>
  </div>
</div>
<p class="error-text" *ngIf="!control.valid && submitted && control.hasError('required')">
  This field is required
</p>
